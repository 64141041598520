.bg {
  background: rgb(2, 0, 36); /* Fallback background color */
  background: linear-gradient(
          90deg, /* Gradient direction */
          rgba(2, 0, 36, 1) 0%, /* Dark blue at the start */
          rgba(15, 7, 133, 1) 33%, /* Deep purple-blue at 33% */
          rgba(16, 21, 148, 1) 57%, /* Slightly lighter purple-blue at 57% */
          rgba(2, 0, 36, 1) 100% /* Dark blue again at the end */
  );
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6, .font-weight-bold {
    font-weight: 500;
}
html {
    background: rgb(2, 0, 36); /* Fallback background color */
    background: linear-gradient(
            90deg, /* Gradient direction */
            rgba(2, 0, 36, 1) 0%, /* Dark blue at the start */
            rgba(15, 7, 133, 1) 33%, /* Deep purple-blue at 33% */
            rgba(16, 21, 148, 1) 57%, /* Slightly lighter purple-blue at 57% */
            rgba(2, 0, 36, 1) 100% /* Dark blue again at the end */
    );
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.bgwhite {
  background-color: white;
  padding: 20px;
  margin-top: 0;
}
.rezervecard {
  padding: 20px;
}
.paddingBottom {
  padding-bottom: 50px;
}
