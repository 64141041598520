/* Default styles */
.navbar-link {
  color: #001845;
  font-size: 18px;
  font-family: "Poppins, sans-serif", serif;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.navbar-link-active {
  color: #063178;
}

/* Tablet view styles */
@media (min-width: 1115px) and (max-width: 1322px) {
  .navbar-link {
    font-size: 13px; /* Adjust the font size for tablet view */
  }

  .navbar-logo {
    width: 80px; /* Adjust the logo size for tablet view */
    height: 80px;
  }

  .navbar-title {
    font-size: 22px; /* Adjust the title font size for tablet view */
  }
}
