.custom-calendar {
    background-color: #f0f8ff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
    color: white;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    color: white;
}

.react-datepicker__day:hover {
    background-color: #d4edda;
}
